// Show popup on page load if no localStorage found
const role = localStorage.getItem('role');
if(role === null) {
    jQuery('#Modal-ChooseRole').modal('show');
}

function saveRolePick(e) {
    const rolePick = jQuery(e).attr('data-role' )
    localStorage.setItem('role', rolePick);
}
